/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/
 */

// You can delete this file if you're not using it
// ビルド時に、SVGファイルは[NotoSansJP-Regular.otf]のフォントで生成している
// フロント側でも、同じfont-familyでSVGを表示するために、以下のimportをしている
// このようにimportすることで、フロント側ではCSSでfamilyを指定するだけ(指定についてはビルド時にSVGのtextにインラインでやっている)で利用できる
import "@fontsource/noto-sans-jp" // https://zenn.dev/chot/articles/5c18e0471abedd

// Tailwind [https://tailwindcss.com/docs/guides/gatsby]
import "./src/tailwind/global.css"

// ページリロード時に、ポジションをトップに持っていく
// これに加えて、リロード時のポジションをトップにしたいページ側で(src\templates\home.tsx のように)、
// ブラウザ側の挙動も制御する[[https://stackoverflow.com/questions/3664381/force-page-scroll-position-to-top-at-page-refresh-in-html]]
export const onInitialClientRender = () => {
  window.scrollTo(0, 0)
}
// なお以下の一行は効かない(バグとのこと)[https://github.com/gatsbyjs/gatsby/issues/19488]
// export const shouldUpdateScroll = () => false
